import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import OurServices from "../components/Index/OurServices"
import TeamMember from "../components/Index/TeamMember"
import RecentProjects from "../components/Index/RecentProjects"
import MainBanner from "../components/DataAnalyticsAIStartup/MainBanner"
import ProjectStartArea from "../components/Index/ProjectStartArea"
import FeaturedService from "../components/DataAnalyticsAIStartup/FeaturedService"
import { Helmet } from "react-helmet"
import TagsForAll from "../components/Common/TagsForAll"
import designRushBlue from "../assets/images/main-banner/DesignRush.png"
import designRushBlack from "../assets/images/main-banner/DesignRush-black.png"
import TechBehemoths from "../assets/images/main-banner/TB-trusted-on-transparent-light-bg.svg"
import ArticleContainer from "../components/Index/ArticleContainer"
import awardBanner from "../assets/images/awards/award-banner.png"

const Home = () => {

  return (
    <div id="home">
      <Layout>
      <TagsForAll pageTitle="Software Development Pakistan- Karachi | Zaavia." />
      <Helmet>
        <meta name='robots' content='index, follow' />
        <link rel="canonical" href="https://zaavia.net/" />
        <meta name="google-site-verification" description="803b7d20a6e36900b6924b2fc492f348" />
        <meta name="p:domain_verify" description="6q3QBZwMaQADGfTyK_BF78y9gyGKSKawdG2yJ5lZV60" />
        <meta name="description" content="A Software Development company in Pakistan with a Global presence - specializing in Healthcare Softwares & Productivity Tools - Get a FREE Demo TODAY!" />
      </Helmet>
      <Navbar />
      <div>
          <img src={awardBanner} alt="award banner" /> 
      </div>
      <MainBanner />
      <FeaturedService />
      <RecentProjects />
      <OurServices />
      <ProjectStartArea />
      <ArticleContainer />
      <TeamMember />
      <Footer designRushBlue={designRushBlue} designRushBlack={designRushBlack} TechBehemoths={TechBehemoths} />
    </Layout>
    </div>
  )
}
export default Home