import React from "react"
import drSaba from "../../assets/images/awards/Dr.-Saba-Jamal.jpg"
import doctor from "../../assets/images/awards/Dr-saba-jamal.jpg"

const ArticleContainer = () => {
  return (
    <div className="m-5">
      <div className="row justify-content-center">
        <div className="col-xl-5 col-lg-6 col-md-6 d-flex mb-4">
          <div className="article-container">
            <h3 className="pb-3 text-center">
              Dr. Saba Jamal introduces 'Digital Error-Management System' for
              safe blood transfusion to the patients
            </h3>
            <div className="my-2 article-image mx-auto">
              <img src={drSaba} alt="Dr.Saba" />
            </div>
            <p>
              KARACHI: Pakistan has been home to many remarkable medical
              professionals whose contributions have shaped national and global
              healthcare. Their dedication and pioneering services in various
              medical fields......
            </p>
            <div>
              <a
                href="https://thefinancialdaily.com/dr-saba-jamal-introduces-digital-error-management-system-for-safe-blood-transfusion-to-the-patients/"
                target="_blank"
                rel='noopener noreferrer'
                className="default-btn"
              >
                <i className="flaticon-right"></i>Read More<span></span>
              </a>
            </div>
          </div>
        </div>

        <div className="col-xl-5 col-lg-6 col-md-6 d-flex mb-4">
          <div className="article-container">
            <h3 className="pb-3 text-center">
              Dr. Saba Jamal's 'Panacea': A game-changer innovation in blood
              banking industry
            </h3>
            <div className="my-2 mx-auto article-image">
              <img src={doctor} alt="Dr.Saba" />
            </div>
            <p>
              Pakistan has some of the most exemplary women leaders working in
              diverse sectors from agriculture to health to technology, making
              their mark in national and international front and contributing to
              Pakistan's development......
            </p>
            <div>
              <a
                href="https://thefinancialdaily.com/dr-saba-jamals-panacea-a-game-changer-innovation-in-blood-banking-industry/"
                target="_blank"
                rel='noopener noreferrer'
                className="default-btn"
              >
                <i className="flaticon-right"></i>Read More<span></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArticleContainer