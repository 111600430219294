import React from 'react'
import {graphql, Link, useStaticQuery} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'

const query = graphql`{
    allStrapiProduct {
        edges {
          node {
            title
            activeTitle
            slug
            ProductsCategory
            categoryImage {
              url
              alternativeText
            }
        }
    }
  }
}`
const RecentProjects = () => {
    const product = useStaticQuery(query)
    const productsCategory = product?.allStrapiProduct?.edges
    const category = [...new Set(productsCategory.map(category => category?.node?.ProductsCategory))]
    return (

        <section className="projects-area products-project-area bg-color pt-50 pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="project" /> Our Products
                    </span>
                    <p> Committed to delivering high quality products and innovate design solutions.</p>
                </div>
                {category.map((category, index) => {
                    return (
                        <fieldset key={index}>
                            <legend><h2>{category}</h2></legend>
                            <div className="row justify-content-center">
                                {productsCategory.map((products, index) => {
                                    return (products?.node?.ProductsCategory === category &&
                                            <div className="col-lg-4 col-md-6" key={index}>
                                                <div className="single-projects-box">
                                                    <div className="image">
                                                        <img style={{ height: '190px' }} src={products?.node?.categoryImage?.url} alt={products?.node?.categoryImage?.alternativeText || "project"} />

                                                        <Link className="link-btn" to={products?.node?.slug}>
                                                            <i className='bx bx-plus'></i>
                                                        </Link>
                                                    </div>

                                                    <div className="content">
                                                        <h3>
                                                            <Link to={products?.node?.slug}>
                                                                {products?.node?.activeTitle}
                                                            </Link>
                                                        </h3>
                                                        <span>{products?.node?.title}</span>
                                                    </div>
                                                </div>
                                            </div>
                                    ) 
                                })}
                            </div>
                        </fieldset>
                    );
                })}

            </div>

        </section>
        

    )
}

export default RecentProjects;