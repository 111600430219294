import React from 'react'
import img13 from '../../assets/images/main-banner/banner-two/Innovatiive-Digital-Solution.png'
import icon4 from '../../assets/images/icons/icon4.png'
import icon5 from '../../assets/images/icons/icon5.png'
import icon6 from '../../assets/images/icons/24-7-support-2.png'
import icon7 from '../../assets/images/icons/icon7.png'



const MainBanner = () => {

    return (    
        <React.Fragment>

            <div className="banner-section">


                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                        <div className="banner-content about-content">
                            <div className="content" >
                                <h1>Software Development Company- Zaavia</h1>
                                <p>Zaavia is a Software Development Company, Pakistan- driven by innovation and a mission to transform the world with cutting-edge IT solutions. Our expertise in <strong>HEALTHCARE SOFTWARE</strong> enhances patient care, streamlines operations, and improves efficiency in the healthcare sector. While our innovative <strong>PRODUCTIVITY TOOLS</strong>  help modern businesses optimize workflows and maximize performance, delivering quality solutions regardless of the human development index. We also offer <strong>CUSTOMIZED SOFTWARE</strong> solutions built to meet your exact requirements!</p>
                                <ul className="features-list">
                                    <li>
                                        <img src={icon4} alt="banner" />
                                        <p className='banner-features'>10+ Years</p>
                                        On the market
                                    </li>
                                    <li>
                                        <img src={icon5} alt="banner" />
                                        <p className='banner-features'>75+</p>
                                        Clients
                                    </li>
                                    <li>
                                        <img style={{width:'50px'}} src={icon6} alt="banner" />
                                        <p className='banner-features'>24/7</p>
                                        Remote support
                                    </li>
                                    <li>
                                        <img src={icon7} alt="banner" />
                                        <p className='banner-features'>Unlimited</p>
                                        Users/Workstations
                                    </li>
                                </ul>
                            </div>
                        </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                        <img src={img13} alt="banner" />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MainBanner